import React from 'react';
import './src/styles/global.css'
import { CustomLinesProvider } from './src/context/LinesContext';
import { CustomSideBarProvider } from './src/context/SideBarContext';
import { CustomNavigationProvider } from './src/context/NavigationContext';

export const wrapRootElement = ({ element }) => (
  <CustomLinesProvider>
    <CustomSideBarProvider>

    <CustomNavigationProvider>{element}</CustomNavigationProvider>
    </CustomSideBarProvider>
  </CustomLinesProvider>
);

