import React, { createContext, useContext, useState, useEffect } from "react";

const LinesContext = createContext();

export const useLinesContext = () => {
  const context = useContext(LinesContext);
  if (!context) {
    throw new Error("useLinesContext must be used within a LinesProvider");
  }
  return context;
};

export const CustomLinesProvider = ({ children }) => {
  const [linesContext, setLinesContext] = useState([]);
  const [addedLines, setAddedLines] = useState(false)

  const updateLines = (newLines) => {
  
   
    if (newLines?.data?.data.hasOwnProperty('cartLinesAdd')) {
      const payload = newLines.data?.data?.cartLinesAdd?.cart?.lines
    
 
      setLinesContext(payload);
      setAddedLines(true)
      
    }
    if (newLines?.data?.data.hasOwnProperty('cart')) {
      const payload = newLines.data?.data?.cart?.lines
    
 
      setLinesContext(payload);
      setAddedLines(true)
      
    }

    if (newLines?.data?.data.hasOwnProperty('cartLinesUpdate')) {
      const payload = newLines.data?.data?.cartLinesUpdate?.cart?.lines
    
 
      setLinesContext(payload);
      setAddedLines(true)
      
    }
  };



  return (
    <LinesContext.Provider value={{ linesContext, updateLines, addedLines}}>
      {children}
    </LinesContext.Provider>
  );
};
