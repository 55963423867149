exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partenaires-js": () => import("./../../../src/pages/Partenaires.js" /* webpackChunkName: "component---src-pages-partenaires-js" */),
  "component---src-pages-parts-js": () => import("./../../../src/pages/parts.js" /* webpackChunkName: "component---src-pages-parts-js" */),
  "component---src-pages-products-personnalisez-js": () => import("./../../../src/pages/products/Personnalisez.js" /* webpackChunkName: "component---src-pages-products-personnalisez-js" */),
  "component---src-pages-products-shopify-collection-handle-index-js": () => import("./../../../src/pages/products/{ShopifyCollection.handle}/index.js" /* webpackChunkName: "component---src-pages-products-shopify-collection-handle-index-js" */),
  "component---src-pages-products-shopify-product-handle-js": () => import("./../../../src/pages/products/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-products-shopify-product-handle-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/Promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-recherche-js": () => import("./../../../src/pages/recherche.js" /* webpackChunkName: "component---src-pages-recherche-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/Service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-temp-js": () => import("./../../../src/pages/temp.js" /* webpackChunkName: "component---src-pages-temp-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-ventes-js": () => import("./../../../src/pages/ventes.js" /* webpackChunkName: "component---src-pages-ventes-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

