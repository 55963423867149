import React, { createContext, useContext, useState, useEffect } from "react";

const NavigationContext = createContext();

export const useNavigationContext = () => {
    const context = useContext(NavigationContext);
    if (!context) {
      throw new Error("useLinesContext must be used within a LinesProvider");
    }
    return context;
  };
  
  export const CustomNavigationProvider = ({ children }) => {
    const [navData, setNavData] = useState(3);

  
    const updateNavData = (data) => {

     console.log(data, "context");
       
        setNavData(data);
      
     
   
    };
  
  
  
    return (
      <NavigationContext.Provider value={{ navData, updateNavData}}>
        {children}
      </NavigationContext.Provider>
    );
  };