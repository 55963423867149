import React, { createContext, useContext, useState, useEffect } from "react";

const SideBarContext = createContext();

export const useSideBarContext = () => {
    const context = useContext(SideBarContext);
    if (!context) {
      throw new Error("useLinesContext must be used within a LinesProvider");
    }
    return context;
  };
  
  export const CustomSideBarProvider = ({ children }) => {
    const [sideOption, setSideOption] = useState(3);

  
    const updateOption = (newOption) => {

     
       
        setSideOption(newOption);
      
     
   
    };
  
  
  
    return (
      <SideBarContext.Provider value={{ sideOption, updateOption}}>
        {children}
      </SideBarContext.Provider>
    );
  };